import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
  IconButton,
  Snackbar,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css';
import { viewDraft, closeIcon, infoIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import LeadManagementController, {
  Props
} from "./LeadManagementController";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import TopNavSkeleton from "../../../components/src/TopNavSkeleton";
import { getPageCount } from "../../../components/src/commonUsage";
import { Pagination } from "@material-ui/lab";
import moment from "moment";

export default class ViewDrafts extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 

  async componentDidMount(){
    this.setState({draftLoading:true})
    this.fetchDraftLeads()
    addEventListener("beforeunload", (event) => {
      this.deleteDisableDrafts()
      return "leaved the page"
    });
  }

  async componentWillUnmount() {
     this.deleteDisableDrafts()
  }

  renderViewDrafts() {
    return <Box>
      <Box>
        <TableContainer className="lead-table" component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ background: '#f8f9fa' }}>
              <TableRow>
                <TableCell className="lead-table-heading extraPadd" style={{ width: '227' }}>Deal name</TableCell>
                <TableCell className="lead-table-heading" style={{ maxWidth: '230px', width: '230px' }}>CUSTOMER EMAIL</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '150px' }}>Last updated</TableCell>
                <TableCell className="lead-table-heading" style={{ width: '302px' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.draftLeads.data.length?
                this.state.draftLeads.data.map((lead:any,index:number) => (
                  <TableRow className={`row-container ${lead.disable?"disable-row":""}`} key={index}>
                    <TableCell className="lead-table-data-row captialize-name extraPadd">
                      {`${lead.first_name} ${lead.last_name}`}
                    </TableCell>
                    <TableCell
                      className="lead-table-data-row"
                      style={{ maxWidth: '230px', width: '230px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                      {lead.customer_email}
                    </TableCell>
                    <TableCell className="lead-table-data-row capitalize-text">
                      {moment(lead.updated_at).format('HH:mm DD/MM/YYYY')}
                    </TableCell>
                    {
                      lead.disable ?
                        <TableCell className="draft-save-cell">
                          <Box className="draft-save-tooltip">
                            This draft has been auto-removed
                            <Tooltip
                              title="It has been auto-removed since another deal has been saved using the same email"
                              placement="top-end"
                              arrow
                            >
                              <Typography className="draft-link">
                                <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell> :
                        <TableCell className="view-draft-action">
                          <div
                            className="edit-cell"
                            style={webStyles.clickEdit}
                          >
                            <div className="mr-32 edit-btn" onClick={()=>this.navigateToEditLead(lead)}>
                              Edit draft
                            </div>
                            <div onClick={() => this.openDiscardModal(lead.id)} className="discard-btn">
                              Discard
                            </div>
                          </div>
                        </TableCell>
                    }
                  </TableRow>
                )):<></>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper view-draft-wrapper">{
            this.state.isLoading ?
              <Box className="skeleton-wrapper">
                <TopNavSkeleton />
                <PageHeadingSkeleton />
                <TableFilterSkeleton hasTabbing={true} />
                <TableSkeleton />
              </Box>
              :
              <Grid>
                <TopNavBar currentPage={{ name: 'Referrals', path: 'referrals' }} subPage={{ name: 'Register referral' }} />
                <Box style={webStyles.mainContainer}>
                  <div className="page-title-block">
                    <div className="left-block">
                      <Typography className="deal-heading-main">
                        Register referral
                      </Typography>
                      <Typography className="deal-heading-sub">
                        Please fill out the required fields to register a new referral.
                      </Typography>
                    </div>
                  </div>
                  <Grid className="in-table-theme">
                    <Box sx={{ width: '100%' }}>
                      <Box className="view-draft-wrap">
                        <Box style={webStyles.container}>
                          <Box className="referral-heading-wrapper">
                            <Box className="deal-heading-left">
                              <Typography className="deal-form-heading">
                                <label className="deal-form-heading-icon">
                                  <img src={viewDraft} />
                                </label>Drafts
                              </Typography>
                            </Box>
                            <Box className="deal-heading-right">
                              <Box className="btn-wrapper">
                                <Button
                                  className="custom-btn btn-md text-sm purple-btn"
                                  onClick={() => {
                                    localStorage.removeItem("leadFormData")
                                    this.props.navigation.navigate('RegisterReferral')
                                  }}
                                  style={{ textTransform: 'none' }}>
                                  Register new referral
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          <Grid>
                            {this.state.draftLoading ?
                              <div>
                                {/* <Loader loading={this.state.isLoading} contentLoader={true} /> */}
                                {/* skeleton start  */}
                                <Box className="skeleton-wrapper">
                                  <TableSkeleton></TableSkeleton>
                                </Box>
                                {/* skeleton end  */}
                              </div>
                              :
                              <>
                                {this.renderViewDrafts()}
                              </>
                            }
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>

                {this.state.draftLeads.meta.total_count >= 10 &&
                  <Box className="table-page-info-block">
                    <Box className="pagination-label-wrapper">
                      <Typography className="pagination-text">
                        Showing {getPageCount(this.state.currentPage,this.state.draftLeads.meta.total_count)} out of {this.state.draftLeads.meta.total_count} results
                      </Typography>
                    </Box>
                    <Box className="pagination-wrapper">
                      <Pagination
                        data-test-id="pagination"
                        count={this.state.draftLeads.meta.total_pages}
                        onChange={(event, page) => {
                          this.setState({ currentPage: parseInt(page + "") }, () => {
                            this.fetchDraftLeads();
                          });
                        }} page={this.state.currentPage || 1} shape="rounded" />
                    </Box>
                  </Box>
                } 

              </Grid>
          }
          </Grid>
        </Grid>

        {/* discard modal  */}
        <Modal
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
          open={this.state.discardModal}
          onClose={this.closeDiscardModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="discard-modal custom-modal modal-xs"
        >
          <Box className="modal-body">
            <IconButton data-test-id="btnCloseDiscardModal" disableRipple className="close-btn" onClick={() => this.closeDiscardModal()}><img src={closeIcon} alt="close" /></IconButton>

            <Box className="modal-content">
              <Box className="content-wrap">
                <Typography variant="h4">Would you like to discard this draft?</Typography>
                <Typography>All the made changes will be deleted for this referral and you might have to start over</Typography>
              </Box>

              <Box className="btn-wrap">
                <Button onClick={() => this.closeDiscardModal()} className="custom-btn gray-btn">Cancel</Button>
                <Button onClick={() => {
                  if(this.state.draftLeads.deleteDraftId){
                  this.deleteDraftLeads()
                  this.closeDiscardModal()}
                }} className="custom-btn red-btn" variant="outlined">Discard</Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Discard Snackbar  */}
        <Snackbar
          open={this.state.discardToastOpen}
          autoHideDuration={6000}
          onClose={this.discardToastClose}
          message="Your draft has been deleted"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}

          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                disableRipple
                className="close-btn"
                onClick={(event) => this.discardToastClose(event, 'manual')}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Box>
      // Customizable Area End
    );
  }
}


// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  clickEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
// Customizable Area End
